import { trackEvent } from "@/libs/analytics/Analytics";
import { AuthenticatedUser } from "@/libs/authentication/user";
import { storeVideoEvent } from "./gemini_analytics_store";
import { ClientParameters } from "@/libs/ClientParameterProvider";
import { CalendarEvent } from "@/renderers";
import { cli } from "winston/lib/winston/config";

export const trackFormSubmit = (
  clientParams: ClientParameters,
  form_id: string,
  form_name?: string,
  user?: AuthenticatedUser
) => {
  trackEvent(
    clientParams,
    {
      event: "form_submit",
      form_id: form_id,
      form_name: form_name,
    },
    user
  );
};

export const trackDownload = (
  clientParams: ClientParameters,
  assetType: string,
  assetId: string | number | undefined,
  user?: AuthenticatedUser
) => {
  trackEvent(
    clientParams,
    {
      event: `File Downloads:${assetType}`,
      type: "Asset",
      label: `${assetId}`,
    },
    user
  );
};

export const trackAddToCalendar = (
  calendarEvent: CalendarEvent,
  clientParams: ClientParameters,
  user?: AuthenticatedUser
) => {
  trackEvent(
    clientParams,
    {
      event: `Add To Calendar`,
      label: `${calendarEvent.title}`,
    },
    user
  );
};

export const trackRegistrationFormFilled = (clientParams: ClientParameters) => {
  trackEvent(clientParams, {
    event: "Register:registration_form_submit",
    type: "Form",
    label: "success",
  });
};

export const trackSearchTextEntered = (
  clientParams: ClientParameters,
  searchText: string,
  user?: AuthenticatedUser
) => {
  trackEvent(
    clientParams,
    {
      event: "Search Text Entered",
      search_text: searchText,
    },
    user
  );
};

export const trackSortInteraction = (
  clientParams: ClientParameters,
  sort_type: string,
  user?: AuthenticatedUser
) => {
  trackEvent(
    clientParams,
    {
      event: "Sort Interaction",
      sort_type: sort_type,
    },
    user
  );
};

export const trackFilterInteraction = (
  clientParams: ClientParameters,
  queryParams: any,
  user?: AuthenticatedUser
) => {
  trackEvent(
    clientParams,
    {
      event: "Filter Interaction",
      ...queryParams,
    },
    user
  );
};

export const trackMarketoFormSubmit = (
  clientParams: ClientParameters,
  form_id: string,
  title: string,
  user?: AuthenticatedUser
) => {
  trackEvent(
    clientParams,
    {
      event: "Marketo Form Submit",
      form_id: form_id,
      form_title: title,
    },
    user
  );
};

export const trackPaywallView = (
  clientParams: ClientParameters,
  title: string,
  user?: AuthenticatedUser
) => {
  trackEvent(
    clientParams,
    {
      event: "Paywall View",
      title: title,
    },
    user
  );
};

interface VideoTrackingProps {
  videoUrl: string;
  videoTitle: string;
  playerState: "Started" | "Playing" | "Paused" | "Completed";
  percentagePlayed: number;
  currentTime: number;
  accessToken?: string;
}

export const trackVideoView = async (
  clientParams: ClientParameters,
  trackingProps: VideoTrackingProps,
  user?: AuthenticatedUser
) => {
  const { videoTitle, playerState } = trackingProps;

  trackEvent(
    clientParams,
    {
      event: `Video ${playerState}`,
      type: "Talk",
      label: videoTitle,
    },
    user
  );

  storeVideoEvent(trackingProps);
};

export const trackPdfViewInteraction = (
  trackingProps: {
    interactionType: "next_page" | "previous_page";
    title: string;
    category: string;
    publishDate: string;
    percentageRead?: number;
  },
  clientParams: ClientParameters,
  user?: AuthenticatedUser
) => {
  trackEvent(
    clientParams,
    {
      event: "report_interaction",
      interaction_type: trackingProps.interactionType,
      title: trackingProps.title,
      category: trackingProps.category,
      publish_date: trackingProps.publishDate,
      percentage_read: trackingProps.percentageRead,
    },
    user
  );
};
