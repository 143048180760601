import { useState } from "react";
import { Lightbox } from "@horizon/components";
import { MediaPlayerData } from "@/renderers";
import { MediaPlayerComponent } from "@/components";
import { Analytics } from "@/libs/analytics";
import { AuthenticatedUser, Authentication } from "@/libs";
import { useClientParameters } from "@/libs/ClientParameterProvider";

export const LightboxComponent = ({
  activeIndex,
  media,
  isOpen,
  setIsOpen,
  shouldAutoplay = false,
  showDownloadCta,
}: {
  activeIndex?: number;
  media: MediaPlayerData[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  shouldAutoplay: boolean;
  showDownloadCta: boolean;
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  // This state value controls whether the media in the Lightbox should autoplay
  // upon opening the Lightbox (relevant to videos). The autoplay should
  // trigger only when both: (a) enabled, (b) Lightbox opened externally
  // When the Lightbox is open and user uses controls to navigate between assets
  // the autoplay will not trigger, even when enabled
  const [autoplay, setAutoplay] = useState(shouldAutoplay);
  const user = Authentication.useAuthenticatedUser();
  const clientParams = useClientParameters();

  if (media.length === 0) return null;

  const handleClose = (): void => {
    setIsOpen(false);
    // Reset the autoplay value upon closing the Lightbox
    if (shouldAutoplay) {
      setAutoplay(true);
    }
  };

  const handleIndexChange = (index: number): void => {
    setCurrentIndex(index);
    // When the Lightbox is open and user uses controls to navigate between assets
    // the autoplay will not trigger, even when enabled
    if (shouldAutoplay && isOpen && index !== activeIndex) {
      setAutoplay(false);
    }
  };

  const content = media
    .map((item, index) => ({
      item: MediaPlayerComponent({
        ...item,
        constrainHeight: true,
        center: true,
        playing: autoplay,
      }),
      title: item.title,
    }))
    .filter((contentItem) => contentItem.item);

  return (
    <Lightbox
      content={content}
      ctas={ctas({ currentIndex, media, user, clientParams, showDownloadCta })}
      closeLightbox={handleClose}
      activeIndex={activeIndex}
      isOpen={isOpen}
      onIndexChange={handleIndexChange}
    />
  );
};

const ctas = ({
  currentIndex,
  media,
  user,
  clientParams,
  showDownloadCta,
}: {
  currentIndex: number;
  media: MediaPlayerData[];
  user: AuthenticatedUser | undefined;
  clientParams: any;
  showDownloadCta: boolean;
}) => {
  const ctas = [];
  if (showDownloadCta) {
    ctas.push({
      buttonWidth: ["full", "auto"],
      variant: "primary",
      children: "Download",
      href: `/api/assets/download/${media[currentIndex].media.id}`,
      download: true,
      icon: {
        name: "Download",
        position: "end",
      },
      onClick: () => {
        Analytics.trackDownload(
          clientParams,
          media[currentIndex].media.mediaType,
          media[currentIndex].media.id,
          user
        );
      },
    });
  }
  return ctas;
};
