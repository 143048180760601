import { QueryParams } from "@/libs/QueryParams";
import {
  awardToTagData,
  awardsToFestivalTagData,
  classicToAwardTagData,
} from "@/services/libs/award_utils";

import {
  AwardTagData,
  CardData,
  ComponentData,
  ComponentGroupingData,
  MediaContent,
} from "@/renderers";
import {
  listToCommaSeparatedString,
  PATHS,
  toDateFormat,
  yearFromDateTime,
} from "@/services/libs";
import { ComponentMapper } from "@/services/providers/contentful/mappers";
import { ComponentGrouping } from "@/services/providers/contentful/types";
import {
  Campaign,
  ClassicAsset,
  Editorial,
  Entry,
  GroupedContent,
  Inspiration,
  Talk,
  YoungEntry,
} from "@/services/providers/gemini/types";
import { createId } from "@/components/libs";

export const toComponentGroupingData = ({
  autoplayCarousel,
  carouselControlPosition,
  carouselItemsIndicator,
  componentsCollection,
  componentsPerRow,
  link,
  maxRecords,
  noResultsText,
  page,
  pageSize,
  searchQuery,
  title,
  totalRecords,
  variant,
}: ComponentGrouping): ComponentData => {
  let results = {
    type: "ComponentGrouping",
    autoplayCarousel,
    carouselControlPosition,
    carouselItemsIndicator,
    componentsPerRow,
    cta: link ? { label: link.label, href: link.link } : undefined,
    maxRecords,
    noResultsText,
    searchQuery,
    title,
    totalRecords,
    variant,
    id: createId(title),
  } as ComponentGroupingData;
  if (maxRecords && componentsCollection.items) {
    componentsCollection.items = componentsCollection.items.slice(
      0,
      maxRecords
    );
  }
  results = mapComponentsToCards(searchQuery, results, componentsCollection);
  results = maybeAddPagination(
    page,
    pageSize,
    maxRecords,
    totalRecords,
    results,
    componentsPerRow
  );
  return results as ComponentData;
};

export const talkToCard = ({
  title,
  thumbnail,
  slug,
  startsAt,
  speakers,
  stage,
}: Talk): CardData => ({
  type: "Card",
  variant: "Video",
  title,
  link: slug
    ? {
        label: title,
        href: `${PATHS.talks}/${slug}`,
      }
    : undefined,
  media: {
    href: thumbnail,
    alt: title || "",
    mediaType: "image/jpeg",
  },
  superText: `${yearFromDateTime(startsAt)} ${stage || ""}`,
  subText:
    speakers &&
    listToCommaSeparatedString(speakers.map((speaker) => speaker.name)),
});

export const campaignToCard = ({
  assets,
  awardCount,
  shortlisted,
  brand,
  company,
  festival,
  slug,
  title,
  year,
}: Campaign): CardData => {
  const awardTag =
    awardCount || shortlisted
      ? awardsToFestivalTagData(awardCount, festival.name)
      : undefined;
  return {
    type: "Card",
    variant: "Campaign",
    title,
    link: slug
      ? { label: title, href: `${PATHS.campaigns}/${slug}` }
      : undefined,
    media: {
      href: (assets && assets[0]?.thumbnailUrl) || "",
      alt: title || "",
      mediaType: "image/jpeg",
    },
    superText: listToCommaSeparatedString([company?.name, company?.town]),
    subText: `${year}, ${brand?.name}`,
    mediaTag: awardTag,
  };
};

export const entryToCard = (entry: Entry): CardData => {
  return {
    type: "Card",
    variant: "Entry",
    title: entry.title,
    tag: entry.tags && entry.tags[0],
    link: entry.slug
      ? { label: entry.title, href: `${PATHS.entries}/${entry.slug}` }
      : undefined,
    media: {
      href: (entry.assets && entry.assets[0].thumbnailUrl) || "",
      alt: entry.title || "",
      mediaType: "image/jpeg",
    },
    superText: entry.entryType?.category?.name,
    subText: listToCommaSeparatedString([
      entry.brand?.name,
      entry.company?.name,
    ]),
    mediaTag: entry.entryType?.award
      ? awardToTagData(entry.entryType.award)
      : undefined,
  };
};

export const inspirationToCard = (inspiration: Inspiration): CardData => {
  return {
    type: "Card",
    variant: "Entry",
    title: inspiration.title,
    link: inspiration.slug
      ? {
          label: inspiration.title,
          href: `${PATHS.inspiration}/${inspiration.slug}`,
        }
      : undefined,
    media: {
      href: inspiration.imageUrl || "",
      alt: inspiration.title || "",
      mediaType: "image/jpeg",
    },
    superText: toDateFormat(inspiration.liveAt),
  };
};

export const editorialToCard = (editorial: Editorial): CardData => {
  let href = `${PATHS.video}/${editorial.slug}`;
  if (editorial.mediaType === "PDF") {
    href = `${PATHS.reports}/${editorial.slug}`;
  }

  return {
    type: "Card",
    variant: "Editorial",
    title: editorial.title,
    link: editorial.slug
      ? {
          label: editorial.title,
          href: href,
        }
      : undefined,
    media: {
      href: editorial.imageUrl || "",
      alt: editorial.title || "",
      mediaType: "image/jpeg",
    },
    superText: toDateFormat(editorial.publishedDate),
    subText: editorial.theme,
  };
};

export const classicToCard = (classic: ClassicAsset): CardData => {
  return {
    type: "Card",
    variant: "Entry",
    title: classic.title,
    tag: classic.country,
    link: { label: classic.title, href: `${PATHS.classic}/${classic.slug}` },
    media: {
      href: classic.imageUrl,
      alt: classic.title || "",
      mediaType: "image/jpeg",
    },
    superText: classic.lion,
    subText: listToCommaSeparatedString([
      classic.year?.toString(),
      classic.brand,
      classic.agency,
    ]),
    mediaTag: classicToAwardTagData(classic.award),
  };
};

export const youngEntryToCard = (youngEntry: YoungEntry): CardData => {
  const media = youngEntry?.asset?.thumbnailUrl
    ? ({
        href: youngEntry.asset.thumbnailUrl,
        alt: youngEntry.title || "",
        mediaType: "image/jpeg",
      } as MediaContent)
    : undefined;
  let mediaTag = undefined;
  if (youngEntry.shortlisted) {
    mediaTag = {
      type: "Tag",
      content: "Shortlisted",
      component: "Award",
      variant: "shortlist",
    } as AwardTagData;
  }
  if (youngEntry.youngEntryAward) {
    mediaTag = awardToTagData(youngEntry.youngEntryAward) as AwardTagData;
  }

  return {
    type: "Card",
    variant: "Entry",
    title: youngEntry.title,
    link: {
      label: youngEntry.title,
      href: `${PATHS.youngEntries}/${youngEntry.slug}`,
    },
    media: media,
    superText: youngEntry.country,
    caption: listToCommaSeparatedString([youngEntry.year?.toString()]),
    subText: youngEntry.entryTypeName,
    mediaTag,
  };
};

const mapComponentsToCards = (
  searchQuery: string | undefined,
  results: ComponentGroupingData,
  componentsCollection: {
    items: GroupedContent[] | null;
  }
) => {
  if (searchQuery) {
    switch (QueryParams.parseSearchQuery(searchQuery)?.content_type) {
      case "talks":
        results.components =
          componentsCollection.items?.map((item) => talkToCard(item as Talk)) ||
          ([] as ComponentData[]);
        break;
      case "campaigns":
        results.components =
          componentsCollection.items?.map((item) =>
            campaignToCard(item as Campaign)
          ) || ([] as ComponentData[]);
        break;
      case "entries":
        results.components =
          componentsCollection.items?.map((item) =>
            entryToCard(item as Entry)
          ) || ([] as ComponentData[]);
        break;
      case "editorials":
        results.components =
          componentsCollection.items?.map((item) =>
            editorialToCard(item as Editorial)
          ) || ([] as ComponentData[]);
        break;
      case "inspirations":
        results.components =
          componentsCollection.items?.map((item) =>
            inspirationToCard(item as Inspiration)
          ) || ([] as ComponentData[]);
        break;
      case "classic":
        results.components =
          componentsCollection.items?.map((item) =>
            classicToCard(item as ClassicAsset)
          ) || ([] as ComponentData[]);
        break;
      case "young_entries":
        results.components =
          componentsCollection.items?.map((item) =>
            youngEntryToCard(item as YoungEntry)
          ) || ([] as ComponentData[]);
        break;
    }
  } else {
    results.components =
      componentsCollection.items?.map(ComponentMapper.buildComponent) ||
      ([] as ComponentData[]);
  }
  return results;
};

const maybeAddPagination = (
  page: number | undefined,
  pageSize: number | undefined,
  maxRecords: number | undefined,
  totalRecords: number | undefined,
  results: ComponentGroupingData,
  componentsPerRow: number | number[] | undefined
) => {
  const componentsPerRowCount = Array.isArray(componentsPerRow)
    ? componentsPerRow[componentsPerRow.length - 1]
    : componentsPerRow;
  const pageComparator = componentsPerRowCount || pageSize;
  if (
    pageComparator &&
    results.components &&
    results.components.length < pageComparator
  ) {
    return results;
  }
  if (totalRecords && pageSize && totalRecords < pageSize) return results;
  if (maxRecords && pageSize && maxRecords < pageSize) return results;

  if (page && pageSize && totalRecords) {
    results.page = page;
    results.pageSize = pageSize;
    results.totalRecords = totalRecords;
  }
  return results;
};
