import { Sort } from "@/services/libs";
import { sanitize } from "isomorphic-dompurify";
import { defaultsDeep } from "lodash";

export const replaceAllQueryParams = (newQueryParams: {
  [key: string]: string | string[];
}) => {
  const queryParams = new URLSearchParams();
  Object.entries(newQueryParams).forEach(([key, value]) => {
    value = Array.isArray(value) ? value.toString() : value;
    if (value) {
      queryParams.append(key, value);
    }
  });
  return updateURL(queryParams);
};

export const updateURL = (queryParams: URLSearchParams) => {
  window.location.search = queryParams.toString();
};

export const updateHistory = (queryParams: URLSearchParams) => {
  history.pushState(
    null,
    "filter",
    `${window.location.pathname}?${queryParams.toString()}`
  );
};

export const combineQueryParams = (
  searchQuery: string | undefined,
  queryParams: string | undefined
): ParsedQuery => {
  const parsedQuery = parseSearchQuery(queryParams);
  const parsedSearchQuery = parseSearchQuery(searchQuery);
  return defaultsDeep(parsedQuery, parsedSearchQuery);
};

export const replaceQueryParam = (key: string, value: string | string[]) => {
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.has(key)) {
    queryParams.delete(key);
  }
  if (queryParams.has(PAGE_PARAM) && key !== PAGE_PARAM) {
    queryParams.delete(PAGE_PARAM);
  }
  value = Array.isArray(value) ? value.toString() : value;
  if (value) {
    queryParams.append(key, value);
  }
  return updateURL(queryParams);
};

export const currentSearchQuery = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.toString();
};

export const parseSearchQuery = (
  searchquery: string | undefined
): ParsedQuery => {
  const cleanSearchQuery = sanitize(searchquery || "");
  const regex: RegExp = /&(?=[^&]*=)/; // Select the first '&' before every '='
  const queryKeyValues = cleanSearchQuery
    .split(regex)
    .map(splitKeyValues)
    .map(([key, value]) => [key, maybeParseInt(key as string, value)])
    .map(([key, value]) => [key, maybeReplaceAmpersand(value)]);
  if (queryKeyValues) {
    const queryParams = Object.fromEntries(queryKeyValues);
    if (!queryParams.hasOwnProperty("undefined")) {
      return queryParams;
    }
  }
  return undefined;
};

const splitKeyValues = (keyValue: string): (string | string[])[] => {
  const splitValue = keyValue.split("=");
  if (
    splitValue.length === 2 &&
    splitValue[1] !== "" &&
    NON_ARRAY_PARAMS.includes(splitValue[0])
  ) {
    return splitValue;
  } else if (splitValue.length === 2 && splitValue[1] !== "") {
    return [splitValue[0], splitValue[1].split(",")];
  }
  return [];
};

const maybeParseInt = (key: string, value: string | string[]) => {
  if (INTEGER_PARAMS.includes(key)) {
    return Array.isArray(value)
      ? value.map((v) => parseInt(v))
      : parseInt(value);
  }
  return value;
};

const NON_ARRAY_PARAMS = [
  "sort",
  "content_type",
  "page",
  "brand_id",
  "search_text",
  "agency_id",
  "token",
  "state",
];
const INTEGER_PARAMS = [
  "years",
  "year",
  "brand_id",
  "entry_type_id",
  "categories",
  "page",
  "agency_id",
];
export const PAGE_PARAM = "page";

const maybeReplaceAmpersand = (
  value: string | number | string[] | number[]
) => {
  if (typeof value === "string") {
    return value.replace("%26", "&");
  }
  if (Array.isArray(value)) {
    return value.map((v) => {
      if (typeof v === "string") {
        return v.replace("%26", "&");
      }
      return v;
    });
  }
  return value;
};

export const queryToString = (query: ParsedQuery) => {
  if (query) {
    const queryParams = new URLSearchParams();
    Object.entries(query).forEach(([key, value]) => {
      value = Array.isArray(value) ? value.toString() : value;
      if (value) {
        queryParams.append(key, value);
      }
    });
    return queryParams.toString();
  }
  return "";
};

export const assureDefaultSort = (
  queryParams: ParsedQuery,
  sortOption: Sort.SortValue
): ParsedQuery | undefined => {
  if (!queryParams) return undefined;
  return queryParams.sort ? queryParams : { sort: sortOption, ...queryParams };
};

export type ParsedQuery = Record<string, string | string[]> | undefined;
