import { createId } from "@/components/libs";
import {
  BrandColour,
  ComponentData,
  ContainerData,
  SectionBlockData,
  SpacerData,
} from "@/renderers";

export const buildDarkSectionData = (
  content: (ComponentData | undefined)[],
  title?: string
): SectionBlockData => {
  return {
    type: "SectionBlock",
    backgroundMode: "dark",
    darkModeBackgroundColour: "primary",
    content,
    title,
    id: createId(title),
  };
};

export const buildSectionData = (
  content: (ComponentData | undefined)[],
  title?: string,
  backgroundColor?: BrandColour
): SectionBlockData => {
  return {
    type: "SectionBlock",
    backgroundMode: "light",
    darkModeBackgroundColour: backgroundColor,
    content,
    title,
    id: createId(title),
  };
};

export const buildContainerData = (
  children: (ComponentData | undefined)[]
): ContainerData => {
  return {
    type: "Container",
    children,
  };
};

export const buildSpacerData = (
  content: (ComponentData | undefined)[],
  size: "small" | "medium" | "large" = "medium"
): SpacerData => {
  return {
    type: "Spacer",
    size,
    content,
  };
};
