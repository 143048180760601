import { LightboxComponent } from "@/components";
import { GalleryBlockData, MediaPlayerData } from "@/renderers";
import { GalleryBlock } from "@horizon/components";
import { useState } from "react";

export const GalleryBlockComponent = ({
  media,
  showDownloadCta,
  showLightboxCta,
  id,
}: GalleryBlockData) => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  if (media.length === 0) return null;
  const lightboxMedia = media.filter((asset) => {
    return asset.media.href;
  });

  const isGalleryAvailable = showLightboxCta && lightboxMedia.length > 0;

  return (
    <>
      <GalleryBlock
        id={id}
        cta={
          isGalleryAvailable && {
            onClick: () => {
              setIsLightboxOpen(true);
            },
            icon: {
              name: "Image",
              size: "end",
            },
            variant: "secondary",
          }
        }
        mediaItems={buildGalleryMediaItems(media)}
      />
      {isGalleryAvailable && (
        <LightboxComponent
          media={lightboxMedia}
          isOpen={isLightboxOpen}
          setIsOpen={setIsLightboxOpen}
          shouldAutoplay={false}
          showDownloadCta={showDownloadCta}
        />
      )}
    </>
  );
};

const buildGalleryMediaItems = (media: MediaPlayerData[]) => {
  return media
    .map((item) => {
      if (item.media.thumbnail)
        return {
          src: item.media.thumbnail,
          alt: item.media.alt,
          mediaType: "image",
        };
    })
    .filter((item) => item);
};
