import { claimLicense } from "@/services/providers/gemini/functions/claimLicense";
import { getBrandById } from "@/services/providers/gemini/functions/getBrandById";
import { getCampaignById } from "@/services/providers/gemini/functions/getCampaignById";
import { getCampaignFilters } from "@/services/providers/gemini/functions/getCampaignFilters";
import { getClassicAssetById } from "@/services/providers/gemini/functions/getClassicAssetById";
import { getEntryById } from "@/services/providers/gemini/functions/getEntryById";
import { getEntryFilters } from "@/services/providers/gemini/functions/getEntryFilters";
import { getFestivalTracks } from "@/services/providers/gemini/functions/getFestivalTracks";
import { getFilteredContent } from "@/services/providers/gemini/functions/getFilteredContent";
import { getRelatedCampaigns } from "@/services/providers/gemini/functions/getRelatedCampaigns";
import { getInspirationById } from "@/services/providers/gemini/functions/getInspirationById";
import { getTalkById } from "@/services/providers/gemini/functions/getTalkById";
import { getTalksByExternalIds } from "@/services/providers/gemini/functions/getTalkById";
import { getTalkFilters } from "@/services/providers/gemini/functions/getTalkFilters";
import { getFilteredBrands } from "@/services/providers/gemini/functions/getFilteredBrand";
import { getBrandFilters } from "@/services/providers/gemini/functions/getBrandFilters";
import { getFilteredAgencies } from "@/services/providers/gemini/functions/getFilteredAgency";
import { getAgencyFilters } from "@/services/providers/gemini/functions/getAgencyFilters";
import { getAgencyById } from "@/services/providers/gemini/functions/getAgencyById";
import { getIndividualById } from "@/services/providers/gemini/functions/getIndividualById";
import { getMyProfile } from "@/services/providers/gemini/functions/getMyProfile";
import { getCollections } from "@/services/providers/gemini/functions/getCollections";
import { getCollection } from "@/services/providers/gemini/functions/getCollection";
import { getIndividualFilters } from "@/services/providers/gemini/functions/getIndividualFilters";
import { getFilteredIndividuals } from "@/services/providers/gemini/functions/getFilteredIndividuals";
import { getRankingsData } from "@/services/providers/gemini/functions/getRankingsData";
import { getRankingsFilters } from "@/services/providers/gemini/functions/getRankingsFilters";
import { getYoungEntryById } from "@/services/providers/gemini/functions/getYoungEntryById";
import { getClassicFilters } from "@/services/providers/gemini/functions/getClassicFilters";
import { getReportById } from "@/services/providers/gemini/functions/getReportById";
import { getVideoById } from "@/services/providers/gemini/functions/getVideoById";
import { getSearchCounts } from "@/services/providers/gemini/functions/getSearchCounts";
import { getAwardResults } from "@/services/providers/gemini/functions/getAwardResults";

export const GeminiProvider = {
  claimLicense,
  getEntryById,
  getFilteredContent,
  getCampaignById,
  getCampaignFilters,
  getEntryFilters,
  getFestivalTracks,
  getClassicAssetById,
  getBrandById,
  getTalkFilters,
  getRelatedCampaigns,
  getTalkById,
  getTalksByExternalIds,
  getInspirationById,
  getFilteredBrands,
  getBrandFilters,
  getFilteredAgencies,
  getAgencyFilters,
  getAgencyById,
  getIndividualById,
  getMyProfile,
  getCollections,
  getCollection,
  getIndividualFilters,
  getFilteredIndividuals,
  getRankingsData,
  getRankingsFilters,
  getYoungEntryById,
  getClassicFilters,
  getReportById,
  getVideoById,
  getSearchCounts,
  getAwardResults,
};
