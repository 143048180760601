import { gql } from "graphql-request";

export const GET_SEARCH_COUNTS = gql`
  query getSearchCounts($searchText: String) {
    getSearchCounts(filters: { search_text: $searchText }) {
      campaignCount
      editorialCount
      inspiraionCount
      talkCount
      totalCount
    }
  }
`;
