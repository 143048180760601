const NAVIGATION_ITEM_FRAGMENT = `fragment NavigationItemFragment on NavigationItem {
  navigationItemLabel
  navigationItemDescription
  url
  requiredUserAction
}`;

export const NAVIGATION_CONTAINER_BY_ID = `query($preview: Boolean, $navigationId: String!, $locale: String)
{
  navigationContainer(id: $navigationId, preview: $preview, locale: $locale) {
    navigationItemsCollection {
      items {
        ...NavigationItemFragment
        childrenNavigationItemsCollection {
          items {
            ...NavigationItemFragment
          }
        }
      }
    }
  }
}
${NAVIGATION_ITEM_FRAGMENT}
`;
