import {
  ComponentData,
  GalleryCarouselData,
  PageTitleBlockData,
  SaveCollectionButtonData,
  TabContainerData,
  TableData,
} from "@/renderers";
import { Entry, Collection, Award } from "@/services/providers/gemini/types";
import { listToCommaSeparatedString, typeFromAsset } from "../../libs";
import { ComponentGrouping } from "@/services/providers/contentful/types";
import { GeminiMapper } from "@/services/providers/gemini/mappers";
import { PATHS, buildContainerData, buildSpacerData } from "@/services/libs";
import { awardToTagData } from "@/services/libs/award_utils";
import { UserAction } from "@/libs/authentication/user";

const toTitleData = (
  entry: Entry,
  collections?: Collection[]
): PageTitleBlockData => {
  const { brand, campaign, company, entryType, title, year } = entry;
  let awardTags = {};
  if (entryType.award !== null) {
    awardTags = { awardTags: [awardToTagData(entryType.award as Award)] };
  }
  return {
    type: "PageTitleBlock",
    title: title,
    superText: `${entryType?.name} > ${entryType?.section?.name}`,
    subText:
      listToCommaSeparatedString([company?.name, company?.town]) +
      ` / ` +
      brand?.name +
      " / " +
      year,
    ...awardTags,
    ctas: [
      {
        type: "Button",
        variant: "secondary",
        icon: { name: "Layout", position: "end" },
        label: "Campaign",
        href: `${PATHS.campaigns}/${campaign?.slug}`,
      },
    ],
    saveCollectionButtonData: toCollectionsSaveButton(entry, collections),
  };
};

const toGalleryCarousel = (
  { assets }: Entry,
  userActions?: UserAction[]
): GalleryCarouselData => ({
  type: "GalleryCarousel",
  userActions,
  media: assets.map((asset) => ({
    href: asset.fullUrl || "",
    alt: asset.label || "",
    mediaType: typeFromAsset(asset),
    thumbnail: asset.thumbnailUrl,
    id: asset.id as number,
  })),
});

const maybeToGalleryCarousel = (
  entry: Entry,
  userActions?: UserAction[]
): GalleryCarouselData | null => {
  return entry.assets.length ? toGalleryCarousel(entry, userActions) : null;
};

const toTabContainer = (entry: Entry): TabContainerData => ({
  type: "TabContainer",
  reloadOnTabChange: false,
  tabs: [
    {
      type: "Tab",
      title: "Overview",
      label: "Overview",
      components: [buildContainerData(toOverviewTab(entry))],
    },
    {
      type: "Tab",
      title: "Credits",
      label: "credits",
      components: [buildContainerData([buildSpacerData(toCreditsTab(entry))])],
    },
  ],
});

const toOverviewTab = ({ caseStudy }: Entry): ComponentData[] => {
  const content = caseStudy.reduce(
    (accumulator: any[], entry) => {
      accumulator.push({
        type: "Caption",
        content: entry?.question,
        size: "medium",
      });
      accumulator.push({
        type: "Markdown",
        html: entry.response
          ?.split("\n")
          .filter(Boolean)
          .map((str) => `<p>${str}</p>`)
          .join(""),
        copySize: "medium",
      });
      return accumulator;
    },
    [
      {
        type: "HeroTitle",
        content: "OVERVIEW",
        size: "medium",
      },
    ]
  );

  return [
    {
      type: "Spacer",
      size: "medium",
      content,
    } as ComponentData,
  ];
};

const toCreditsTab = (entry: Entry): TableData[] => {
  const companies = entry.credits?.companies?.map((company) => {
    return {
      cells: [
        {
          label: {
            copy: company.name,
            href: company.slug ? `${PATHS.agencies}/${company.slug}` : "",
            size: "small",
          },
          type: company.slug ? "link" : "label",
        },
        {
          label: {
            copy: company.location,
            size: "small",
          },
        },
        {
          label: {
            copy: company.role,
            size: "small",
          },
        },
      ],
    };
  });
  const people = entry.credits?.people?.map((person) => {
    return {
      cells: [
        {
          label: {
            copy: person.name,
            href: person.slug ? `${PATHS.people}/${person.slug}` : "",
            size: "small",
          },
          type: person.slug ? "link" : "label",
        },
        {
          label: {
            copy: person.company,
            size: "small",
          },
        },
        {
          label: {
            copy: person.role,
            size: "small",
          },
        },
      ],
    };
  });

  return [
    entry.credits?.companies && {
      type: "Table",
      title: "Companies",
      headers: [
        { label: { copy: "Company" } },
        { label: { copy: "Location" } },
        { label: { copy: "Role" } },
      ],
      rows: companies,
    },
    entry.credits?.people && {
      type: "Table",
      title: "People",
      headers: [
        { label: { copy: "Name" } },
        { label: { copy: "Company" } },
        { label: { copy: "Role" } },
      ],
      rows: people,
    },
  ].filter(Boolean) as TableData[];
};

const toFooterCarousels = (componentGroupings: ComponentGrouping[]) => {
  return componentGroupings.map(GeminiMapper.toComponentGroupingData);
};

const toCollectionsSaveButton = (
  entry: Entry,
  collections?: Collection[]
): SaveCollectionButtonData | undefined => {
  if (!entry.saveable) return;
  return {
    type: "SaveCollectionButton",
    collections: collections?.map(GeminiMapper.toCollectionData),
    contentType: "entry",
    contentId: entry.id.toString(),
  };
};

export const EntryShowMapper = {
  toTitleData,
  toGalleryCarousel,
  maybeToGalleryCarousel,
  toTabContainer,
  toFooterCarousels,
};
