import { CaptionData } from "@/renderers";
import { Caption } from "@horizon/components";
import { buildKey } from "./libs";

export const CaptionComponent = (
  { size, content, id }: CaptionData,
  index?: number
) => {
  return (
    <Caption key={buildKey("caption", index)} size={size} id={id}>
      {content}
    </Caption>
  );
};
