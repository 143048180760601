import { useState, Dispatch, SetStateAction } from "react";
import { GalleryCarousel, Image } from "@horizon/components";
import { LightboxComponent } from "@/components";
import { GalleryCarouselData } from "@/components/components.d";
import { MediaPlayerData, MediaContent, MediaType } from "./media.d";
import {
  USER_ACTIONS,
  UserAction,
  userHasAction,
} from "@/libs/authentication/user";

export const GalleryCarouselComponent = ({
  media,
  userActions,
}: GalleryCarouselData) => {
  const [isOpen, setIsOpen] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);

  const isGalleryAvailable = media.length > 0;
  const slides = media.map((item, index) =>
    buildSlideObject(item, index, setIsOpen, setSlideIndex, userActions)
  );
  const mediaPlayerData = media.map((item) => buildMediaPlayerData(item));
  return (
    <>
      {isGalleryAvailable && (
        <>
          <GalleryCarousel slides={slides} />
          <LightboxComponent
            activeIndex={slideIndex}
            media={mediaPlayerData}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            shouldAutoplay={true}
            showDownloadCta={true}
          />
        </>
      )}
    </>
  );
};

export const buildMediaPlayerData = (
  mediaContent: MediaContent
): MediaPlayerData => ({
  type: "MediaPlayerData",
  media: mediaContent,
});

export const buildSlideObject = (
  mediaContent: MediaContent,
  index: number,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  setSlideIndex: Dispatch<SetStateAction<number>>,
  userActions?: UserAction[]
) => {
  const slideCallback = () => {
    setSlideIndex(index);
    setIsOpen(true);
  };
  const { id, mediaType, thumbnail, alt } = mediaContent;
  const component = <Image mediaType="image" src={thumbnail} alt={alt} />;
  const cta = id ? buildCta(mediaType, id, slideCallback) : undefined;
  return {
    component,
    cta,
    downloadLink:
      userHasAction(USER_ACTIONS.ASSETS_DOWNLOAD, userActions) && cta
        ? cta.href
        : undefined,
    fullscreenCallback: slideCallback,
  };
};

export interface IGalleryCarouselCta {
  href: string;
  icon: { name: string; color?: string };
  fullscreenCallback: () => void;
}

const buildCta = (mediaType: MediaType, id: number, callback: () => void) => {
  const ctaProps: Partial<IGalleryCarouselCta> = {
    href: `/api/assets/download/${id}`,
  };
  switch (mediaType) {
    case "video/mp4":
    case "audio/mp3":
      ctaProps.icon = { name: "PlayFull" };
      break;
    case "application/pdf":
      ctaProps.icon = { name: "Pdf" };
      break;
    case "image/jpeg":
    case "image/png":
    case "image/zoom":
      ctaProps.icon = { name: "ZoomIn" };
      break;
  }
  ctaProps.fullscreenCallback = callback;

  return ctaProps;
};
