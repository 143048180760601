import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import type {
  AvatarData,
  ComponentData,
  FeaturePanelData,
  ComponentGroupingData,
  SpacerData,
  CardData,
  SectionBlockData,
  TextBlockData,
  HeroBlockData,
  TagData,
  MediaPlayerData,
  AccordionData,
  UploadFileData,
  PageTitleBlockData,
  BannerData,
  ButtonData,
  AwardTagData,
  MarketoFormData,
  PaywallData,
  FilterBlockData,
  MediaContent,
  QuoteData,
  EntriesFilter,
  LivePLayerData,
  AnnouncementData,
  GalleryBlockData,
  TextComponentData,
  RichTextBlockData,
  ContainerData,
} from "@/renderers";
import {
  Avatar,
  Card,
  ComponentGrouping,
  FeaturePanel,
  SectionComponent,
  RichTextBlock,
  HeroBlock,
  Tag,
  MediaPlayer,
  Accordion,
  PageTitleBlock,
  MarketoForm,
  Quote,
  LivePlayer,
  SponsorBanner,
  Announcement,
  GalleryBlock,
} from "@/services/providers/contentful/types";
import { UploadFile } from "../types/uploadFile.d";
import { PaywallComponent } from "@/services/providers/contentful/types/paywall_component.d";
import { Media } from "../types/common.d";
import { createId } from "@/components/libs";
import { RichTextMapper } from "./richText.mapper";

const buildComponents = (components: any[]) => components?.map(buildComponent);

const buildComponent = (content: any): ComponentData => {
  switch (content.type) {
    case "Card":
      return buildCard(content);
    case "FeaturePanel":
      return buildFeaturePanel(content);
    case "ComponentGrouping":
      return buildComponentGrouping(content);
    case "Section":
      return buildSectionData(content);
    case "EntriesFilter":
      return buildEntriesFilters(content);
    case "TextBlock":
      return buildRichTextBlock(content);
    case "HeroBlock":
      return buildHeroBlock(content);
    case "MediaPlayer":
      return buildMediaPlayer(content);
    case "Accordion":
      return buildAccordion(content);
    case "Avatar":
      return buildAvatar(content);
    case "UploadFile":
      return buildUploadFile(content);
    case "PageTitleBlock":
      return buildPageTitleBlock(content);
    case "MarketoForm":
      return buildMarketoForm(content);
    case "Quote":
      return buildQuote(content);
    case "LivePlayer":
      return buildLivePlayer(content);
    case "Banner":
      return buildBanner(content);
    case "Announcement":
      return buildAnnouncement(content);
    case "GalleryBlock":
      return buildGalleryBock(content);
    default:
      return buildSpacer(content);
  }
};

const buildAvatar = ({
  image,
  name,
  size,
  subText,
  tag,
}: Avatar): AvatarData => {
  const imageProps = image
    ? ({
        href: image.url,
      } as MediaContent)
    : undefined;

  return {
    type: "Avatar",
    image: imageProps,
    name,
    size,
    subText,
    tag: tag && buildTagData(tag),
  };
};

const buildSpacer = ({ content, size }: SpacerData): SpacerData => {
  return {
    content,
    size,
    type: "Spacer",
  };
};

const buildGalleryBock = ({
  imagesCollection,
}: GalleryBlock): GalleryBlockData => {
  const media = imagesCollection.items.map((image: Media) => {
    return {
      type: "MediaPlayerData",
      media: {
        href: image.url,
        alt: image.title,
        mediaType: image.contentType,
        thumbnail: image.url,
      },
      thumbnail: image.url,
      title: image.title,
    };
  });

  return {
    type: "GalleryBlock",
    media: media as MediaPlayerData[],
    showDownloadCta: false,
    showLightboxCta: false,
  };
};

const buildAnnouncement = ({
  announcementMessageRichText,
  date,
  expiredMessageRichText,
  variant,
}: Announcement): AnnouncementData => {
  return {
    type: "Announcement",
    announcementMsg:
      announcementMessageRichText &&
      documentToHtmlString(announcementMessageRichText.json),
    expiredMsg:
      expiredMessageRichText &&
      documentToHtmlString(expiredMessageRichText.json),
    date,
    variant,
  };
};

const buildBanner = ({ media, text }: SponsorBanner): BannerData => {
  return {
    type: "Banner",
    image: media
      ? {
          src: media?.url,
        }
      : undefined,
    label: text,
    position: "flex-start",
    id: createId(text),
  };
};

const buildLivePlayer = ({
  liveStreamUrl,
  loopVideoStream,
  startTimeOffsetSeconds,
  liveEventDate,
}: LivePlayer): LivePLayerData => {
  const url = new URL(liveStreamUrl);
  if (loopVideoStream) {
    url.searchParams.set("autoplay", "1");
    url.searchParams.set("loop", "1");
    url.searchParams.set("muted", "1");
    url.searchParams.set("transparent", "0");
  }
  if (startTimeOffsetSeconds) {
    const minutes = Math.floor(startTimeOffsetSeconds / 60);
    const seconds = startTimeOffsetSeconds % 60;
    url.hash = `t=${minutes}m${seconds}s`;
  }
  return {
    type: "LivePlayer",
    liveStreamUrl: url.toString(),
    // Not sure about this value
    id: createId(`Live Player ${liveEventDate}`),
  };
};

const buildQuote = ({
  copy,
  name,
  jobTitle,
  company,
  variant,
  image,
}: Quote): QuoteData => {
  const media = image
    ? {
        href: image.url,
        alt: image.description || "",
        mediaType: image.contentType,
      }
    : null;

  return {
    type: "Quote",
    copy,
    name,
    jobTitle,
    company,
    variant,
    media: media as MediaContent,
    id: createId(name),
  };
};

const buildMarketoForm = (content: MarketoForm): MarketoFormData => {
  const {
    title,
    formId,
    redirectPage: { slug: redirectSlug },
  } = content;
  return {
    type: "MarketoForm",
    title,
    formId,
    redirectSlug,
    baseUrl: process.env.NEXT_PUBLIC_MARKETO_FORM_BASE_URL || "",
    munchkinId: process.env.NEXT_PUBLIC_MARKETO_MUNCHKIN_ID || "",
    id: createId(title),
  };
};

const buildPageTitleBlock = (content: PageTitleBlock): PageTitleBlockData => {
  const {
    title,
    superText,
    subText,
    quote,
    links,
    profileCard: card,
    awardTags: tags,
  } = content;
  const ctas = links?.items.map((item) => {
    const icon = {
      icon: {
        ...(item.iconName && { name: item.iconName }),
        ...(item.iconPosition && { position: item.iconPosition }),
      },
    };
    return {
      type: "Button",
      label: item.label,
      accessibilityLabel: item.label,
      href: item.link,
      ...(item.iconName && icon),
    };
  }) as ButtonData[];
  const profileCard = card ? buildCard(card) : undefined;
  const awardTags = tags
    ? (tags?.items.map(buildTagData) as AwardTagData[])
    : [];

  return {
    type: "PageTitleBlock",
    title,
    superText,
    subText,
    quote,
    ctas,
    profileCard,
    awardTags,
    id: createId(title),
  };
};

const buildUploadFile = (content: UploadFile): UploadFileData => {
  return {
    title: content.title,
    type: "UploadFile",
    copy: content.copy,
    ctaLabel: content.ctaLabel,
    media: {
      href: content.file.url,
      alt: content.file.description || "",
      mediaType: content.file.contentType,
    },
  };
};

const buildAccordion = (content: Accordion): AccordionData => {
  const link = content.link
    ? { label: content.link.label, href: content.link.link }
    : null;

  return {
    title: content.title,
    type: "Accordion",
    link,
    content: content.contentCollection.items.map(buildTextBlock),
    id: createId(content.title),
  };
};

const buildMediaPlayer = (content: MediaPlayer): MediaPlayerData => {
  return {
    type: "MediaPlayerData",
    media: {
      href: content.media.url,
      alt: content.media.description || "",
      mediaType: content.media.contentType,
    },
    publishDate: content.media.publishedAt,
    thumbnail: content.thumbnail?.url,
    id: createId(content.media.title),
  };
};

const buildHeroBlock = (content: HeroBlock): HeroBlockData => {
  const link = content.link
    ? { label: content.link.label, href: content.link.link }
    : null;
  const media = content.media
    ? {
        href: content.media.url,
        alt: content.media.description || "",
        mediaType: content.media.contentType,
      }
    : null;
  const headlineMedia = content.headlineMedia
    ? {
        href: content.headlineMedia.url,
        alt: content.headlineMedia.description || "",
        mediaType: content.headlineMedia.contentType,
      }
    : null;
  const bannerMedia = content.bannerMedia
    ? {
        href: content.bannerMedia.url,
        alt: content.bannerMedia.description || "",
        mediaType: content.bannerMedia.contentType,
      }
    : null;

  return {
    type: "HeroBlockData",
    title: content.title,
    subtitle: content.subtitle,
    link,
    variant: content.variant,
    media,
    headlineMedia: headlineMedia,
    bannerMessage: content.bannerMessage,
    bannerMedia,
    id: createId(content.title),
  };
};

const buildFeaturePanel = (content: FeaturePanel): FeaturePanelData => {
  const link = content.link
    ? { label: content.link.label, href: content.link.link }
    : null;
  return {
    type: content.type,
    textPosition: content.variant,
    title: content.title,
    copy: content.copy,
    link,
    media: {
      href: content.media.url,
      alt: content.media.description || "",
      mediaType: content.media.contentType,
    },
    id: createId(content.title),
  };
};

const buildEntriesFilters = ({
  filterOptions: filterOptions,
  filtersToShow: filtersToShow,
}: EntriesFilter): FilterBlockData => {
  return {
    type: "FilterBlock",
    filters: filterOptions,
    sortFilter: undefined,
    filtersToShow: filtersToShow,
  };
};

const buildComponentGrouping = (
  content: ComponentGrouping
): ComponentGroupingData => {
  return {
    type: "ComponentGrouping",
    title: content.title || "",
    variant: content.variant,
    ...(content.componentsCollection && {
      components: content.componentsCollection.items?.map(buildComponent) || [],
    }),
    searchQuery: content.searchQuery || "",
    componentsPerRow: content.componentsPerRow,
    totalRecords: content.totalRecords,
    noResultsText: content.noResultsText,
    page: content.page,
    pageSize: content.pageSize,
    carouselControlPosition: content.carouselControlPosition,
    autoplayCarousel: content.autoplayCarousel,
    carouselItemsIndicator: content.carouselItemsIndicator,
    id: createId(content.title),
  };
};

const buildCard = (content: Card): CardData => {
  const mediaTag =
    content.mediaTagsCollection?.items && content.mediaTagsCollection.items[0];
  return {
    type: "Card",
    variant: content.variant,
    title: content.title || "",
    name: content.name || "",
    link: content.link && {
      label: content.link.label,
      href: content.link.link,
    },
    media: {
      href: content.media.url,
      alt: content.media.description || "",
      mediaType: content.media.contentType,
      aspectRatio: content.aspectRatio,
    },
    subText: content.subText,
    superText: content.superText,
    textPosition: content.textPosition,
    caption: content.caption,
    aspectRatio: content.aspectRatio,
    mediaTag: mediaTag && buildTagData(mediaTag),
    primaryTags: content.primaryTagsCollection?.items.map(
      (tag) => tag && buildTagData(tag)
    ),
    secondaryTags: content.secondaryTagsCollection?.items.map(
      (tag) => tag && buildTagData(tag)
    ),
  };
};

const buildTagData = (tag: Tag): TagData => {
  return {
    type: "Tag",
    content: tag.copy,
    variant: tag.variant,
    component: tag.component,
  };
};

const buildSectionData = (section: SectionComponent): SectionBlockData => {
  return {
    type: "SectionBlock",
    title: section.title,
    subtitle: section.subtitle,
    backgroundMode: section.backgroundMode,
    darkModeBackgroundColour: section.darkModeBackgroundColour,
    content: section?.content?.items?.map(buildComponent) || [],
    id: createId(section.title),
  };
};

const buildTextBlock = (content: RichTextBlock): TextBlockData => ({
  type: "HTMLTextBlock",
  heading: content.heading,
  html: documentToHtmlString(content.richTextCopy.json),
  isFeatured: content.isFeatured ?? false,
  id: createId(content.heading),
});

const buildRichTextBlock = (
  content: RichTextBlock
): TextBlockData | ContainerData => {
  if (content.isFeatured) {
    return {
      type: "HTMLTextBlock",
      heading: content.heading,
      html: documentToHtmlString(content.richTextCopy.json),
      isFeatured: content.isFeatured,
    };
  }
  return {
    type: "Container",
    children: [
      {
        type: "RichTextBlock",
        content: RichTextMapper.jsonToTextComponents(
          content.richTextCopy.json
        ) as TextComponentData[],
      } as RichTextBlockData,
    ],
  };
};

const buildPaywallData = (
  content?: PaywallComponent,
  currentPath?: string
): PaywallData | undefined => {
  if (!content) {
    return undefined;
  }
  return {
    type: "Paywall",
    title: content.title || "",
    html: documentToHtmlString(content.copy.json),
    ctas: content.ctasCollection?.items?.map((cta) => ({
      type: "Button",
      label: cta.label,
      accessibilityLabel: cta.label,
      href: replaceLocationPlaceholderWithPath(cta.link, currentPath),
      icon: cta.iconName
        ? { name: cta.iconName, position: cta.iconPosition || "start" }
        : undefined,
      variant: cta.buttonVariant || "primary",
    })),
  };
};

const replaceLocationPlaceholderWithPath = (
  href: string,
  currentPath?: string
) => {
  const encodedPath = Buffer.from(currentPath || "", "utf-8").toString(
    "base64"
  );
  return href.replace("{{location}}", encodedPath);
};

export const ComponentMapper = {
  buildComponent,
  buildComponents,
  buildPaywallData,
};
