import {
  HeaderData,
  HeaderNavItem,
} from "@/components/Header/header_component.d";
import { Authentication } from "@/libs";
import { logoImageUrl } from "@/libs/utils";
import { PATHS } from "@/services/libs";
import { UserContext, useUser } from "@auth0/nextjs-auth0/client";
import { AppHeader, Header as HorizonHeader } from "@horizon/components";
import { useRouter } from "next/router";

export const HeaderComponent = (headerItems: HeaderData) => {
  const user = useUser();
  const pathWithSearch = useRouter().asPath;
  const path = pathWithSearch.split("?")[0];
  const { primaryLinks } = headerItems;

  if (user?.user) {
    return (
      <AppHeader
        account={getAccountCTAS(user)}
        actions={getActions(user)}
        primaryActive={PrimaryActive(path, primaryLinks)}
        primaryLinks={primaryLinks}
        secondaryActive={path}
      />
    );
  } else {
    const userBtn = headerItems.primaryLinks?.slice(-1)[0];
    const primaryLinks = headerItems.primaryLinks?.slice(0, -1);
    return (
      <HorizonHeader
        primaryLinks={primaryLinks}
        primaryActive={PrimaryActive(path, primaryLinks)}
        secondaryActive={path}
        utilityLinks={null}
        userBtn={userBtn}
        cta={loggedOutCTA(pathWithSearch)}
        logoImage={{
          src: logoImageUrl,
        }}
      />
    );
  }
};

const PrimaryActive = (
  currentPath: string,
  primaryLinks: Array<HeaderNavItem> | undefined
) => {
  if (currentPath === PATHS.root) {
    return PATHS.root;
  }

  if (currentPath === PATHS.search) {
    return PATHS.search;
  }
  const parentPath = currentPath.split("/")[1];
  const parentLink = primaryLinks?.find((link) =>
    link.href?.includes(parentPath)
  );
  return parentLink?.href || "/";
};

const getActions = (user: UserContext) => {
  if (user?.user) {
    return [
      {
        accessibilityLabel: "Search through the site",
        href: "/search",
        icon: "Search",
        label: "Search",
        onClick: () => console.log("Search clicked"),
      },
    ];
  }
};

const getAccountCTAS = (user: UserContext) => {
  if (user?.user) {
    return {
      avatar: {
        name: user.user.name,
        image: user.user.picture && { src: user.user.picture },
      },
      menuItems: [
        {
          label: "My collections",
          href: "/account/collections",
          icon: "Heart",
        },
        {
          label: "Account details",
          href: "/account",
          icon: "File",
        },
        {
          label: "Log out",
          href: "/api/auth/logout",
          icon: "Logout",
        },
      ],
    };
  }
};

const loggedOutCTA = (currentPath: string) => {
  return {
    label: "Sign In",
    href: Authentication.signinHrefPath(currentPath),
  };
};
