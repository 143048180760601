import { HeroBlockData } from "@/renderers";
import { Hero } from "@horizon/components";

export const HeroBlockComponent = ({
  title,
  subtitle,
  link,
  variant,
  media,
  headlineMedia,
  bannerMessage,
  bannerMedia,
  id,
}: HeroBlockData) => {
  const imageProps =
    media && ["image/png", "image/jpeg", "image/webp"].includes(media.mediaType)
      ? { src: media.href }
      : null;

  const videoProps =
    media && media.mediaType === "video/mp4" ? { url: media.href } : null;

  const headlineImageProps =
    headlineMedia &&
    ["image/png", "image/jpeg"].includes(headlineMedia.mediaType)
      ? { src: headlineMedia.href }
      : null;

  const bannerProps =
    bannerMessage && bannerMedia
      ? { image: { src: bannerMedia.href }, label: bannerMessage }
      : null;

  return (
    <Hero
      id={id}
      title={title}
      subtitle={subtitle}
      cta={link}
      variant={variant}
      image={imageProps}
      video={videoProps}
      headlineImage={headlineImageProps}
      banner={bannerProps}
    />
  );
};
