import { useState } from "react";
import { TabContainerData, TabData, TabQuery } from "@/renderers";
import { Tabs_v2 as Tabs, Container } from "@horizon/components";
import { ComponentRenderer } from "@/renderers";
import { buildKey } from "./libs";
import { QueryParams } from "@/libs/QueryParams";

export const TabContainerComponent = (
  tabContainerData: TabContainerData,
  index?: number
) => {
  const { tabs, currentTabQuery, reloadOnTabChange, id } = tabContainerData;
  const [selectedTab, setSelectedTab] = useState(
    getIndexForTabLabel(tabs, currentTabQuery)
  );

  if (!tabContainerData || tabs.length === 0) {
    return null;
  }

  return (
    <>
      <Container>
        <Tabs
          id={id}
          key={buildKey("tabs", index)}
          tabs={tabs}
          selectedIndex={selectedTab}
          width={[12]}
          onChangeCallBack={(_: string, index: number) => {
            replaceQueryParam(tabs, reloadOnTabChange, currentTabQuery, index);
            setSelectedTab(index);
          }}
        />
      </Container>

      {tabContent(tabs, selectedTab)}
    </>
  );
};

const getIndexForTabLabel = (
  tabs: TabData[],
  currentTabQuery: TabQuery | undefined
) => {
  const labels = tabs.map((tab: TabData) => tab.label);
  if (currentTabQuery && labels.includes(currentTabQuery.value)) {
    return labels.indexOf(currentTabQuery.value);
  }
  return 0;
};

const replaceQueryParam = (
  tabs: TabData[],
  reloadOnTabChange: boolean | undefined,
  tabQuery: TabQuery | undefined,
  index: number
) => {
  if (!tabQuery) {
    return;
  }

  if (tabs[index].label !== tabQuery.value && reloadOnTabChange) {
    return QueryParams.build()
      .set(tabQuery.key, tabs[index].label)
      .delete("sort")
      .update();
  }

  QueryParams.build()
    .set(tabQuery.key, tabs[index].label)
    .delete("sort")
    .push();
};

const tabContent = (tabs: TabData[], selectedTab: number) =>
  tabs[selectedTab]?.components?.map((component, index) =>
    ComponentRenderer.render(component, index)
  );
