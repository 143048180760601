import {
  updateCollection,
  deleteCollection,
  shareCollection,
  createCollection,
} from "./collection";

import { trackForm } from "./form";

import { getDigitalSchedule } from "./schedule";

export const Api = {
  updateCollection,
  deleteCollection,
  shareCollection,
  createCollection,
  trackForm,
  getDigitalSchedule,
};
