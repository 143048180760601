import { IncomingMessage, ServerResponse } from "http";
import { NextApiRequest, NextApiResponse } from "next";
import { createRemoteJWKSet, decodeJwt, jwtVerify } from "jose";
import auth0 from "@/libs/authentication/auth0";
import { logger } from "@/libs/Logger";
import path from "path";
import { promises as fs } from "fs";

export type Auth0RefreshTokenResponse = {
  access_token?: string;
  expires_in?: number;
  scope?: string;
  id_token?: string;
  token_type?: string;
};

export type JwtPayload = {
  sub: string;
  iss?: string;
  aud?: string | string[];
  exp?: number;
  nbf?: number;
  iat?: number;
  jti?: string;
  [key: string]: any;
};

// Decode JWT token

export const decodeToken = (token: string): object | null => {
  try {
    return decodeJwt(token);
  } catch (e) {
    logger.error("Error decoding token: ", e);
    return null;
  }
};

export const getUserAccessToken = async (
  req: IncomingMessage | NextApiRequest,
  res: ServerResponse<IncomingMessage> | NextApiResponse
): Promise<string | undefined> => {
  try {
    const { accessToken } = await auth0().getAccessToken(req, res);
    return accessToken;
  } catch (e) {
    return undefined;
  }
};

export const getUserActions = async (
  accessToken: string | undefined
): Promise<string[]> => {
  if (!accessToken) return [];
  const jwks = createRemoteJWKSet(new URL(process.env.AUTH0_JWKS_URI!));
  try {
    // Verify the given token
    const { payload } = (await jwtVerify(accessToken, jwks)) || {};

    return payload["https://users.lovethework.com/actions"] as string[];
  } catch (e) {
    logger.error("Authentication failed: Token could not be verified ", e);
  }
  return [];
};

export const isTokenExpired = (
  tokenExpiration: number | undefined
): boolean => {
  if (!tokenExpiration) {
    return true;
  }

  return Date.now() >= tokenExpiration * 1000;
};

// Generate M2M token using Auth0's client credentials endpoint

export const generateM2MToken = async (): Promise<string | null> => {
  const url = process.env.USER_AUTH0_ISSUER_BASE_URL
    ? `${process.env.USER_AUTH0_ISSUER_BASE_URL}/oauth/token`
    : null;

  if (!url) {
    return null;
  }

  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      audience: process.env.USER_AUTH0_AUDIENCE || "",
      client_id: process.env.USER_AUTH0_CLIENT_ID || "",
      client_secret: process.env.USER_AUTH0_CLIENT_SECRET || "",
      grant_type: "client_credentials",
    }),
  };

  try {
    const result = await fetch(url, options);
    const json = await result.json();
    return json["access_token"];
  } catch (e: any) {
    logger.error("Error getting M2M token: ", e);
    return null;
  }
};

export const readMachineToken = async (): Promise<string | null> => {
  try {
    const machineTokenFile = path.resolve(
      "./src/libs/authentication/machine-to-machine-token.txt"
    );
    return await fs.readFile(machineTokenFile, "utf8");
  } catch (e) {
    logger.error("Error reading machine token: ", e);
    return null;
  }
};

// Refresh token using Auth0's refresh token endpoint
// https://auth0.com/docs/secure/tokens/refresh-tokens/use-refresh-tokens

export const refreshAccessToken = async (
  refreshToken: string
): Promise<Auth0RefreshTokenResponse | null> => {
  const url = process.env.AUTH0_ISSUER_BASE_URL
    ? `${process.env.AUTH0_ISSUER_BASE_URL}/oauth/token`
    : null;

  if (!url || !refreshToken) {
    return null;
  }

  const options = {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams({
      grant_type: "refresh_token",
      client_id: process.env.AUTH0_CLIENT_ID || "",
      client_secret: process.env.AUTH0_CLIENT_SECRET || "",
      refresh_token: refreshToken || "",
    }),
  };

  try {
    const result = await fetch(url, options);
    return await result.json();
  } catch (e: any) {
    logger.error("Error refreshing token: ", e);
    return null;
  }
};

const storeMachineToken = async (token: string): Promise<null | void> => {
  if (!token) {
    return null;
  }
  try {
    const machineTokenFile = path.resolve(
      "./src/libs/authentication/machine-to-machine-token.txt"
    );
    await fs.writeFile(machineTokenFile, token);
  } catch (e) {
    logger.error("Error reading machine token: ", e);
    return null;
  }
};

// Read the machine token from a txt file
// Then check if it is valid - non-expired, decode and compare against the exp field
// (this token is valid for 24h)
// If token is valid return it and if it is not, call Auth0 endpoint to
// generate a new one
// Update the text file with the new token and return it

export const getM2MToken = async (): Promise<string | null> => {
  let token = await readMachineToken();

  if (!token) {
    token = await generateM2MToken();

    if (!token) {
      return null;
    }
    await storeMachineToken(token);
  }

  const decodedToken = decodeToken(token) as JwtPayload;
  const isExpired = isTokenExpired(decodedToken?.exp);

  if (!isExpired) {
    return token;
  }

  const machineToken = await generateM2MToken();

  if (!machineToken) {
    return null;
  }

  await storeMachineToken(machineToken);
  return machineToken;
};
