import {
  HeaderData,
  HeaderNavItem,
  FooterData,
  FooterNavComponent,
} from "@/components";
import {
  Navigation,
  NavigationItem,
} from "@/services/providers/contentful/types";

export const toHeaderData = (navigation: Navigation): HeaderData => ({
  primaryLinks: navigation?.items?.map((item) => buildNavItem(item)) || [],
});

const buildNavItem = (item: NavigationItem): HeaderNavItem => ({
  href: item?.url,
  label: item?.navigationItemLabel,
  navItems: item?.childrenNavigationItemsCollection?.items?.map(buildNavItem),
});

export const toFooterData = (navigation: Navigation): FooterData => ({
  columns: navigation?.items?.map((item) => buildFooterColumn(item)) || [],
  social: buildSocial(),
  secondaryLinks: buildSecondaryFooter(),
});

const buildFooterColumn = (item: NavigationItem): FooterNavComponent => ({
  component: {
    href: item?.url,
    label: item?.navigationItemLabel,
    navItems: item?.childrenNavigationItemsCollection?.items?.map((item) => {
      return {
        href: item?.url,
        label: item?.navigationItemLabel,
      };
    }),
  },
  type: "nav",
});

const buildSocial = () => ({
  media: [
    {
      href: "https://www.facebook.com/Cannes.Lions.Festival.of.Creativity/",
      name: "FacebookCircle",
    },
    {
      href: "https://www.instagram.com/cannes_lions/?hl=en",
      name: "InstagramCircle",
    },
    {
      href: "https://x.com/Cannes_Lions",
      name: "TwitterCircle",
    },
    {
      href: "https://www.linkedin.com/company/cannes-lions-international-advertising-festival/",
      name: "LinkedinCircle",
    },
  ],
  text: "Follow us",
});

const buildSecondaryFooter = () => [
  {
    href: "/terms-and-conditions",
    label: "Terms & Conditions",
  },
  {
    label: "Contact Us",
    href: "/contact-us",
  },
  {
    label: "Privacy Policy",
    href: "/privacy-policy",
  },
  {
    label: "Cookies Policy",
    href: "/cookies-policy",
  },
];
