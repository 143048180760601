import React from "react";
// suppress useLayoutEffect warning when not running in browser
if (typeof window === "undefined") {
  React.useLayoutEffect = () => {};
}

import App from "next/app";
import type { AppProps } from "next/app";
import { UserProvider } from "@auth0/nextjs-auth0/client";
import Theme from "@/components/Theme";
import { NavigationService } from "@/services";
import {
  BackToTopComponent,
  HeaderComponent,
  HeaderData,
  FooterComponent,
  FooterData,
} from "@/components";
import { AuthenticatedUser, Authentication } from "@/libs";
import { AnalyticsInitialiser } from "@/libs/analytics/AnalyticsProvider";
import {
  ClientParameterProvider,
  ClientParameters,
} from "@/libs/ClientParameterProvider";

type LeoAppProps = AppProps & {
  headerItems: HeaderData | null;
  footerProps: FooterData | null;
  clientParams: ClientParameters;
  user?: AuthenticatedUser;
};

const LeoApp = (props: LeoAppProps) => {
  const { Component, pageProps, headerItems, footerProps, user, clientParams } =
    props;
  return (
    <ClientParameterProvider parameters={clientParams}>
      <UserProvider>
        <Authentication.AuthenticatedUserContext.Provider value={user}>
          <AnalyticsInitialiser>
            <Theme>
              <HeaderComponent {...headerItems} />
              <Component {...pageProps} />
              <BackToTopComponent />
              <FooterComponent {...footerProps} />
            </Theme>
          </AnalyticsInitialiser>
        </Authentication.AuthenticatedUserContext.Provider>
      </UserProvider>
    </ClientParameterProvider>
  );
};

LeoApp.getInitialProps = async (context: any) => {
  const props = await App.getInitialProps(context);
  const footerProps = await NavigationService.retrieveFooter();
  const {
    ctx: { req, res },
  } = context;

  const user: AuthenticatedUser | undefined = await Authentication.getUser(
    req,
    res
  );

  const headerItems = await NavigationService.retrieveNavigation(user);

  const clientParams = {
    GTM_TAG_ID: process.env.GTM_TAG_ID,
  };

  return {
    ...props,
    headerItems,
    footerProps,
    user,
    clientParams,
  };
};

export default LeoApp;
