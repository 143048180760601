import {
  CardData,
  ComponentData,
  FilterBlockData,
  SearchBarData,
  TabContainerData,
  TabData,
  TextData,
} from "@/renderers";
import {
  FilteredContent,
  FilterOption,
  Pagination,
  SearchCounts,
} from "@/services/providers/gemini/types";
import {
  campaignToCard,
  talkToCard,
  editorialToCard,
  inspirationToCard,
} from "@/services/providers/gemini/mappers/component_grouping";
import {
  Sort,
  TEXT_CONSTS,
  defaultComponentsPerRow,
  formatNumber,
  pluralize,
} from "@/services/libs";
import { GeminiMapper } from "@/services/providers/gemini/mappers";
import { ParsedQuery, QueryParams } from "@/libs/QueryParams";
import { AggregatedTab, PartialTab, Tab, Tabs } from "@/types/tab.d";

export type SearchFilteredContent =
  | FilteredContent
  | FilteredContent[]
  | undefined;

export const AGGREGATE_TAB: AggregatedTab = {
  label: "all",
  copy: "All",
  countKey: "totalCount",
};

export const TABS: Tabs = {
  campaigns: {
    label: "campaigns",
    copy: "Campaigns",
    index: 0,
    build: campaignToCard,
    filter: Sort.DEFAULT_SORT_OPTIONS,
    countKey: "campaignCount",
  },
  talks: {
    label: "talks",
    copy: "Talks",
    index: 1,
    build: talkToCard,
    filter: Sort.CLASSIC_SORT_OPTIONS,
    countKey: "talkCount",
  },
  reports: {
    label: "editorials",
    copy: "Reports",
    index: 2,
    build: editorialToCard,
    countKey: "editorialCount",
  },
  inspirations: {
    label: "inspirations",
    copy: "Inspirations",
    index: 3,
    build: inspirationToCard,
    countKey: "inspiraionCount",
  },
};

const toTabContainer = (
  currentTabQuery: { key: string; value: string } | undefined,
  filteredContent?: SearchFilteredContent,
  parsedQuery?: ParsedQuery,
  filterOptions?: FilterOption[],
  searchCounts?: SearchCounts
): TabContainerData | undefined => {
  return filteredContent
    ? {
        type: "TabContainer",
        currentTabQuery: currentTabQuery,
        reloadOnTabChange: true,
        tabs: [
          toTab(
            AGGREGATE_TAB,
            toGrid(TABS.campaigns, filteredContent, parsedQuery).concat(
              toGrid(TABS.talks, filteredContent, parsedQuery),
              toGrid(TABS.reports, filteredContent, parsedQuery),
              toGrid(TABS.inspirations, filteredContent, parsedQuery)
            ),
            searchCounts
          ),
          ...Object.values(TABS).map((tab) =>
            toTab(
              tab,
              toGrid(tab, filteredContent, parsedQuery, filterOptions),
              searchCounts
            )
          ),
        ],
      }
    : undefined;
};

const toTab = (
  tab: PartialTab,
  components: ComponentData[],
  searchCounts?: SearchCounts
): TabData => {
  const title = searchCounts
    ? `${tab.copy} (${searchCounts[tab.countKey]})`
    : tab.copy;
  return {
    type: "Tab",
    label: tab.label,
    title: title,
    components: components,
  };
};

const toGrid = (
  tab: Tab,
  filteredContent: SearchFilteredContent,
  parsedQuery?: ParsedQuery,
  filterOptions?: FilterOption[]
): ComponentData[] => {
  if (!filteredContent) return [];

  const componentData = [];

  if (filterOptions) {
    componentData.push(
      addFilterBlockData(
        filterOptions,
        parsedQuery,
        tab.filter ?? undefined
      ) as ComponentData
    );
  }

  const { content, pagination } =
    getFilteredContentFromSearchFilteredContent(filteredContent, tab.index) ??
    {};
  if (content && (pagination?.totalRecords || 0) > 0)
    componentData.push(
      buildComponentGrouping(
        filteredContent,
        tab.copy,
        tab.label,
        content ? content.map((content) => tab.build(content)) : [],
        pagination?.totalRecords ?? 0,
        parsedQuery,
        pagination ?? undefined
      )
    );
  return componentData;
};

const addFilterBlockData = (
  filterOptions: FilterOption[],
  parsedQuery?: ParsedQuery,
  sortOptions?: Sort.SortOption[]
): FilterBlockData | null => {
  return GeminiMapper.toFilterBlockData(
    filterOptions,
    parsedQuery,
    sortOptions
      ? Sort.buildSortOptions(
          sortOptions,
          Sort.parseSortValueFromQuery(parsedQuery)
        )
      : undefined
  );
};

const buildComponentGrouping = (
  filteredContent: SearchFilteredContent,
  tabCopy: string,
  tabLabel: string,
  cardComponents: CardData[],
  totalRecords: number,
  parsedQuery?: ParsedQuery,
  pagination?: Pagination
) => {
  return {
    type: "ComponentGrouping",
    title: `${formatNumber(totalRecords)} ${pluralize(
      totalRecords,
      TEXT_CONSTS.RESULT
    )} found in ${tabCopy}`,
    variant: Array.isArray(filteredContent) ? "carousel" : "grid",
    components: cardComponents,
    componentsPerRow: defaultComponentsPerRow,
    page: pagination?.currentPage,
    pageSize: pagination?.pageSize,
    totalRecords: pagination?.totalRecords,
    cta: buildCTA(filteredContent, parsedQuery, tabLabel),

    ...(pagination ? buildPagination(pagination) : {}),
  } as ComponentData;
};

const buildPagination = (pagination: Pagination) => ({
  currentPage: pagination.currentPage,
  pageSize: pagination.pageSize,
  totalRecords: pagination.totalRecords,
});

const buildCTA = (
  filteredContent: SearchFilteredContent,
  parsedQuery?: ParsedQuery,
  contentType?: string
) => {
  const query = Object.assign({}, parsedQuery);
  if (query) {
    query.content_type = contentType || "all";
  }

  if (Array.isArray(filteredContent)) {
    return {
      label: "View All",
      href: `/search?${QueryParams.queryToString(query)}`,
    };
  }
  return undefined;
};

const getFilteredContentFromSearchFilteredContent = (
  filteredContent: SearchFilteredContent,
  index: number = 0
): FilteredContent | undefined => {
  return Array.isArray(filteredContent)
    ? filteredContent[index]
    : filteredContent;
};

const toSearchBarData = (searchText: string): SearchBarData => ({
  type: "SearchBar",
  searchText: searchText,
  searchBarLabel: "Search across Campaigns, Talks, Reports, and Inspirations",
  suggestions: [
    { label: "influencer" },
    { label: "beer" },
    { label: "gaming" },
    { label: "insurance" },
    { label: "AI" },
  ],
});

const toEmptyResultsText = (): TextData => ({
  type: "Text",
  content: "UNFORTUNATELY, NO RESULTS WERE FOUND FOR YOUR SEARCH.",
  size: "xxlarge",
});

export const SearchMapper = {
  toSearchBarData,
  toTabContainer,
  toEmptyResultsText,
};
