import { Footer as HorizonFooter } from "@horizon/components";
import { FooterData } from "@/components/Footer/FooterComponent.d";

export const FooterComponent = (footerData: FooterData | null) => {
  if (!footerData) return null;
  const { columns, social, secondaryLinks } = footerData;

  return (
    <HorizonFooter
      columns={columns}
      social={social}
      secondaryLinks={secondaryLinks}
    />
  );
};
