import { Authentication } from "@/libs";
import { useClientParameters } from "@/libs/ClientParameterProvider";
import { QueryParams } from "@/libs/QueryParams";
import { Analytics } from "@/libs/analytics";
import { SearchBarData } from "@/renderers";
import { SearchBlock } from "@horizon/components";
import { SetStateAction, useState } from "react";

export const SearchBarComponent = ({
  searchText,
  searchBarLabel,
  suggestions,
  id,
}: SearchBarData) => {
  const [text, setSearchText] = useState(searchText || "");
  const user = Authentication.useAuthenticatedUser();
  const clientParams = useClientParameters();

  const onSearchHandler = (label: string) => {
    const searchText = label || text || "";
    Analytics.trackSearchTextEntered(clientParams, searchText, user);
    QueryParams.replaceQueryParam("search_text", searchText);
  };
  return (
    <SearchBlock
      id={id}
      initialValue={text}
      focus
      placeholder={searchBarLabel}
      cta={{
        accessibilityLabel: "Search",
        onClick: onSearchHandler,
        label: "Search",
      }}
      onChange={(e: { target: { value: SetStateAction<string> } }) => {
        setSearchText(e.target.value);
      }}
      suggestions={suggestions}
    />
  );
};
