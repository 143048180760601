import {
  AvatarGroup,
  Carousel_v2 as Carousel,
  CardGroup,
  Container,
  Spacer,
} from "@horizon/components";
import { ComponentRenderer } from "@/renderers/";
import type { ComponentGroupingData } from "@/components/components.d";
import { buildKey } from "./libs";
import { Pagination, TextComponent } from "@/components";

export const GroupingComponent = (
  {
    autoplayCarousel,
    carouselControlPosition,
    carouselItemsIndicator,
    components,
    componentsPerRow,
    cta,
    noResultsText,
    page,
    pageSize,
    title,
    totalRecords,
    variant,
    id,
  }: ComponentGroupingData,
  index?: number
) => {
  const childComponents = components?.map(ComponentRenderer.render);
  const GroupComponent = components?.find(
    (component) => component?.type === "Avatar"
  )
    ? AvatarGroup
    : CardGroup;

  if (!childComponents || childComponents.length === 0) {
    return (
      <Spacer key={buildKey(title || "spacer", index)}>
        <Container>
          <TextComponent
            content={noResultsText || ""}
            size="xxlarge"
            type="Text"
          />
        </Container>
      </Spacer>
    );
  }

  switch (variant) {
    case "grid":
      return (
        <Spacer key={buildKey(title || "spacer", index)}>
          <GroupComponent
            componentsPerRow={componentsPerRow}
            title={title}
            cta={cta}
            id={id}
          >
            {childComponents}
          </GroupComponent>
          <Pagination
            page={page}
            pageSize={pageSize}
            totalRecords={totalRecords}
          />
        </Spacer>
      );
    case "carousel":
      return (
        <Spacer key={buildKey(title || "spacer", index)}>
          <Container>
            <Carousel
              id={id}
              itemIndicator={carouselItemsIndicator}
              autoplay={autoplayCarousel}
              controlsPosition={carouselControlPosition}
              itemsToShow={componentsPerRow}
              title={title}
              cta={cta}
            >
              {childComponents}
            </Carousel>
          </Container>
        </Spacer>
      );
  }
};
