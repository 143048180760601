import { TableCell, TableData, TagData, FilterData } from "@/renderers";
import { FilterOption } from "@/services/providers/gemini/types/filters";
import { ParsedQuery } from "@/libs/QueryParams";
import { createId } from "@/components/libs";

const rankingsTableCells: any = {
  position: {
    alignment: "center",
    type: "label",
    cellWidth: "auto",
  },
  positionDelta: {
    alignment: "center",
    type: "tag",
    cellWidth: "auto",
  },
  name: {
    type: "label",
    cellWidth: "fill",
  },
};

const sameRankingLabel = (value: number, field: string, positions: any[]) => {
  if (field != "position") return `${value}`;
  const matchingPositions = positions.filter(
    (position: number) => position == value
  );
  if (matchingPositions.length > 1) return `= ${value}`;
  return `${value}`;
};

const cellLabel = (
  ranking: { [x: string]: any },
  header: { field: string },
  rankings: { position: number }[]
) => {
  const value = ranking[header.field];
  if (value) {
    const positions = rankings.map(
      (ranking: { position: number }) => ranking.position
    );
    return sameRankingLabel(value, header.field, positions);
  }
  return "";
};

const getRatingTag = (value: number | null): TagData | null => {
  if (value === null) {
    return value;
  }
  const variant = value === 0 ? "same" : value > 0 ? "up" : "down";
  const content = value === 0 ? "=" : `${Math.abs(value)}`;
  return {
    type: "Tag",
    component: "Rating",
    content,
    variant,
  };
};

const getCellData = (
  ranking: { [x: string]: any },
  header: { label: string; field: string },
  rankings: { position: number }[]
): TableCell => {
  const cellConfig = rankingsTableCells[header.field];
  const label = cellLabel(ranking, header, rankings);
  const cellType = cellConfig?.type ?? "label";
  let cellData = {};

  if (cellType === "tag") {
    cellData = {
      tag: getRatingTag(ranking.positionDelta),
    };
  }

  return {
    ...cellConfig,
    ...cellData,
    label: {
      copy: label,
    },
  };
};

export const rankingsDataToTableData = (component: any): TableData => {
  const {
    title,
    rankingsData: { rankings, rankingsHeaders },
  } = component;

  const headers = rankingsHeaders.map(
    (header: { label: string; field: string }, index: number) => {
      return {
        alignment: rankingsTableCells[header.field]?.alignment ?? "left",
        cellWidth: rankingsTableCells[header.field]?.cellWidth ?? "fill",
        label: {
          copy: header.label,
        },
      };
    }
  );

  const rows = rankings?.map((ranking: { [x: string]: any }) => {
    const cells = rankingsHeaders.map((header: any, index: number) =>
      getCellData(ranking, header, rankings)
    );
    return { cells };
  });

  return {
    type: "Table",
    title: title,
    headers,
    rows,
    id: createId(title),
  };
};

export const parseRankingFilterOptions = (
  filterOptions: FilterOption[],
  parsedQuery: ParsedQuery
): FilterData[] => {
  return filterOptions?.map((campaignFilter) =>
    toFilterData(campaignFilter, parsedQuery)
  );
};

const toFilterData = (
  { key, values, label }: FilterOption,
  parsedQuery: ParsedQuery
): FilterData => {
  return {
    key: key,
    label: label,
    options: values.map((option) => ({
      label: option.label,
      value: option.key,
    })),
    initialValue: parsedQuery ? getInitialValue(parsedQuery[key]) : [],
    multiselect: false,
  };
};

const getInitialValue = (value: string | number | string[]): string[] => {
  if (Array.isArray(value)) {
    return value;
  }
  return [`${value}`];
};
