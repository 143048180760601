import { PageTitleBlockData } from "@/components";
import { YoungEntry } from "../providers/gemini/types";
import {
  AwardTagData,
  ComponentData,
  GalleryCarouselData,
  TabContainerData,
} from "@/renderers";
import { buildContainerData, buildSpacerData, typeFromAsset } from "../libs";
import { awardToTagData } from "../libs/award_utils";
import { UserAction } from "@/libs/authentication/user";

const toTitleData = (youngEntry: YoungEntry): PageTitleBlockData => {
  const { title, country, youngEntryAward } = youngEntry;
  const awardTags = youngEntryAward
    ? [awardToTagData(youngEntryAward) as AwardTagData]
    : [];
  return {
    type: "PageTitleBlock",
    title: title,
    subText: country,
    awardTags,
  };
};

const maybeToGalleryCarousel = (
  youngEntry: YoungEntry,
  userActions?: UserAction[]
): GalleryCarouselData | null => {
  return youngEntry.assets?.length
    ? toGalleryCarousel(youngEntry, userActions)
    : null;
};

const toGalleryCarousel = (
  { assets }: YoungEntry,
  userActions?: UserAction[]
): GalleryCarouselData => ({
  type: "GalleryCarousel",
  userActions,
  media: assets
    ? assets.map((asset) => ({
        href: asset.fullUrl || "",
        alt: asset.label || "",
        mediaType: typeFromAsset(asset),
        thumbnail: asset.thumbnailUrl,
        id: asset.id as number,
      }))
    : [],
});

const toTabContainer = (youngEntry: YoungEntry): TabContainerData => ({
  type: "TabContainer",
  reloadOnTabChange: false,
  tabs: [
    {
      type: "Tab",
      title: "Overview",
      label: "Overview",
      components: [buildContainerData(toOverviewTab(youngEntry))],
    },
    {
      type: "Tab",
      title: "Credits",
      label: "credits",
      components: [
        buildContainerData([buildSpacerData(toCreditsTab(youngEntry))]),
      ],
    },
  ],
});

const toOverviewTab = ({ synopses }: YoungEntry): ComponentData[] => {
  if (!synopses) return [];
  const content = synopses.reduce(
    (accumulator: any[], synopsis) => {
      accumulator.push({
        type: "Caption",
        content: synopsis?.question,
        size: "medium",
      });
      accumulator.push({
        type: "Markdown",
        html: synopsis.answer
          ?.split("\n")
          .filter(Boolean)
          .map((str) => `<p>${str}</p>`)
          .join(""),
        copySize: "medium",
      });
      return accumulator;
    },
    [
      {
        type: "HeroTitle",
        content: "OVERVIEW",
        size: "medium",
      },
      ,
    ]
  );
  return [
    {
      type: "Spacer",
      size: "medium",
      content,
    } as ComponentData,
  ];
};

const toCreditsTab = ({ credits }: YoungEntry): ComponentData[] => {
  const rows = credits
    ? credits.map((credit) => {
        return {
          cells: [
            {
              label: {
                copy: credit.name,
                size: "small",
              },
            },
            {
              label: {
                copy: credit.company,
                size: "small",
              },
            },
            {
              label: {
                copy: credit.role,
                size: "small",
              },
            },
          ],
        };
      })
    : [];
  return [
    {
      type: "Table",
      title: "People",
      headers: [
        { label: { copy: "Name" } },
        { label: { copy: "Company" } },
        { label: { copy: "Role" } },
      ],
      rows,
    } as ComponentData,
  ];
};

export const YoungEntryShowMapper = {
  toTitleData,
  maybeToGalleryCarousel,
  toTabContainer,
  toCreditsTab,
};
